<template>
  <section class="wrapper">
    <div class="status-block">
      <div class="d-flex" style="justify-content: space-between">
        <h5 class="mb-3 ml-1">Назначение наблюдателя</h5>
        <div class="c-pointer font-weight-bold" @click="close">x</div>
      </div>
      <div class="input-wrapper my-3">
        <div class="text-muted">
          Введите ФИО пользователя, чтобы назначить его наблюдателем для
          выбранной техники
        </div>
        <b-input
          type="text"
          v-model="employeeSearchViewer"
          list="viewers-list"
          placeholder="Наблюдатель"
          @change="findAndSelectViewer"
          autocomplete="off"
        />
        <datalist id="viewers-list" class="filter-datalist">
          <option
            v-for="viewer in sortedEmployeeByInputViewer"
            :key="viewer.id"
            :data-value="viewer.id"
            @click="findAndSelectViewer"
            >{{ viewer.first_name }} {{ viewer.last_name }}</option
          >
        </datalist>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-4">
        <div class="btn btn-second" @click="applyEmployee">Сохранить</div>
        <div class="clear ml-3 pseudo-link" @click="close">Закрыть</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AddViewerModal",
  props: {
    employees: {
      type: Array
    }
  },
  data() {
    return {
      employeeSearchViewer: "",
      selectedViewer: 0
    };
  },
  computed: {
    sortedEmployeeByInputViewer() {
      let newEmployees = this.employees.filter(empl => {
        return (
          empl.last_name
            .toLowerCase()
            .includes(this.employeeSearchViewer.toLowerCase()) ||
          empl.first_name
            .toLowerCase()
            .includes(this.employeeSearchViewer.toLowerCase())
        );
      });

      return newEmployees;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    findAndSelectViewer(selectedViewerValue) {
      this.selectedViewer = this.employees.find(
        empl => `${empl.first_name} ${empl.last_name}` === selectedViewerValue
      )?.id;
    },
    applyEmployee() {
      this.$emit("apply", this.selectedViewer);
      this.employeeSearchViewer = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
  overflow: auto;
}

.status-block {
  background: var(--main-card-color);
  border-radius: 6px;
  padding: 20px;
  width: 480px;
  margin: 120px auto;
  transform: translateY(3%);
}

.btn {
  padding: 1em !important;
}

.btn:disabled:hover {
  background-color: rgba(201, 201, 201, 0.7) !important;
  cursor: not-allowed;
}

.btn-light {
  color: #006b90;
  border: 1px solid rgba(0, 107, 144, 0.7);
  background-color: transparent;
}
.btn-light:hover {
  color: #ffffff;
  background-color: rgba(0, 107, 144, 0.7);
}

.font_semibold {
  font-weight: 600;
}

.lbl {
  min-width: 170px;
  width: 170px !important;
  white-space: nowrap;
}

.form-group__label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--main-text-color);
  position: relative;
}

.form-control {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}

.required-label:after {
  content: "*";
  font-size: 26px;
  color: red;
  position: absolute;
}

.pseudo-link {
  cursor: pointer;
}
</style>
