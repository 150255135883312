<template>
  <section class="wrapper">
    <div class="table-view-settings">
      <div class="d-flex justify-content-between">
        <h5 class="mb-3 ml-1">Настройка табличного вида</h5>
        <div class="c-pointer font-weight-bold" @click="close">x</div>
      </div>
      <div class="scroll-container mt-2" :key="settingsKey">
        <div
          class="d-flex align-items-center justify-content-between text-muted text-uppercase font-prebold text-small mb-1"
        >
          <div class="ml-4">Столбец</div>
          <div class="mr-4">Видимость</div>
        </div>
        <div
          class="settings-item d-flex align-items-center justify-content-between"
          v-for="item in srcCopy"
          :key="item.title"
        >
          <div class="left-side d-flex align-items-center">
            <div
              class="pseudo-link"
              title="Переместить вверх"
              @click="moveUp(item)"
            >
              <img
                width="24"
                height="24"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVR4nO2ZS07DMBCG50ycAUQqngdgA1LVZsZnyUwOBStEOQFd9BClCx6yzSKJ3ZIN0bjMJ80msSf+9NtZJACGYRjGUUJyGaposK0A+T1WW0GREJ8D8RZIvkKh7KBurqBoCSpRBtsqK9GVIb6GoiWoBBkcKaFapm4uwpupv9CPzOL71/wcP1cFjmdpEvwJJMtMEvepjOwA2xudEsh1uD8U8SA/6JJxPEu2U1din8ghGce300qQnMXDmkjMB+PyIlFm/rMFBzLN6XQiyC8ZiUUy7pBI7LNIZIifJ7LwC5Cn5GDn+E0kjln2Zfjxj1fffXhzAsivQLIGJ3f7x40Q8fgevhfJKvRWx1gR9ZCJKMMS0YYlog1LRBuWiDYsEW1YItqwRLRxRImsO18V36BYXPj2tYlV6o8ewzAM4z/wDWzV7nVTEVZfAAAAAElFTkSuQmCC"
              />
            </div>
            <div
              class="pseudo-link"
              title="Переместить вниз"
              @click="moveDown(item)"
            >
              <img
                width="24"
                height="24"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVR4nO2Y20rDQBCGf/S9vCjiAVRErTcqouJFZvYd+gaZyWv4XB7QC1FErbUe2MTWtkmwHoi7MB/sTdgJ8+2fDckChmEYhhEqrJtguQbJFVjWEC0sF2B9+xiniBYeShQjWthEAsMSCQ1LJDQskdCwRELDEgkNSyQ0LJHQCD4RyvbAegPWS1C69WsRf4/it/gcrCtohPbJLEhuPxuUfi72U5F8UaQ/nENyhkbodGbyk5GxJvNG9r8t4mtGJbhJEQ9l2+UG9AWsB1OL+LlFzfiCJLKBRnHaBunzRCOvSJS+FCE5rJRwutusxPgm7dXKVIk4PaqUoHQH/4p/y5B0SzKkriRCclwh0Wv+carDyTpIn0oykyKT13yNrw0KJ8tgeSw3XzOClBhA2dJUMnl6oR9qsyyC9KFeQrpI0lVEQZItVMoUL4WGPkH+CpI5sNyNbPR7uHQeUeLSVi5QSLQQNS5txS9hGIZhoJp3c3vxsckOrE0AAAAASUVORK5CYII="
              />
            </div>
            <div class="ml-2">{{ item.title }}</div>
          </div>
          <div class="right-side d-flex align-items-center">
            <!-- <input
              type="number"
              v-model="item.width"
              class="form-control width-input"
            />
            <span class="ml-1">px</span> -->
            <b-form-checkbox class="mb-1" v-model="item.value" switch />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-4">
        <div class="btn btn-second pseudo-link" @click="apply">Сохранить</div>
        <div class="clear ml-3 pseudo-link" @click="close">Закрыть</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TableViewSettings",
  props: {
    src: {
      type: Array
    }
  },
  data() {
    return {
      settingsKey: 0,
      srcCopy: []
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    apply() {
      this.$emit("apply", this.srcCopy);
    },
    moveUp(item) {
      const foundItemIdx = this.srcCopy.findIndex(i => i.title === item.title);
      if (foundItemIdx > 0) {
        [this.srcCopy[foundItemIdx], this.srcCopy[foundItemIdx - 1]] = [
          this.srcCopy[foundItemIdx - 1],
          this.srcCopy[foundItemIdx]
        ];
      }
      this.srcCopy = this.srcCopy.slice(0);
    },
    moveDown(item) {
      const foundItemIdx = this.srcCopy.findIndex(i => i.title === item.title);
      if (foundItemIdx < this.srcCopy.length - 2) {
        [this.srcCopy[foundItemIdx], this.srcCopy[foundItemIdx + 1]] = [
          this.srcCopy[foundItemIdx + 1],
          this.srcCopy[foundItemIdx]
        ];
      }
      this.srcCopy = this.srcCopy.slice(0);
    }
  },
  mounted() {
    this.srcCopy = JSON.parse(JSON.stringify(this.src));
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 3;
}

.attachment {
  padding: 66px 65px 82px 65px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 453px;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  &__header {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.333333px;
    color: #393952;
  }
  &__wrapper {
    margin-top: 35px;
    &__info {
      width: 350px;
      height: 46px;
      background: #e5f1f5;
      border-radius: 10px;
      margin-top: 21px;
      padding-left: 20px;
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;
      cursor: pointer;
      :first-child {
        margin-top: 0;
      }
      span {
        font-family: "Raleway";
        position: absolute;
        left: 80px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.333333px;
        color: #393952;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.vue-select {
  width: 150px;
  margin: 0 5px;
}
.select2-dropdown--above {
  display: flex;
  flex-direction: column;
}
.select2-dropdown--above .select2-search--dropdown {
  order: 2;
}
.select2-dropdown--above .select2-results {
  order: 1;
}

.select2-dropdown--below {
  display: flex;
  flex-direction: column;
}
.select2-dropdown--below .select2-search--dropdown {
  order: 1;
}
.select2-dropdown--below .select2-results {
  order: 2;
}
.select2-container .select2-selection--single {
  height: 20px !important;
}
.add-stage-text {
  color: #51a2be;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}
.add-stage-btn {
  width: 20px;
  padding: 0;
  height: 20px;
  border: 2px solid #51a2be;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  img {
    position: relative;
    top: -5px;
    width: 100%;
    height: 100%;
  }
}
.addstage {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}
.time-btn {
  width: 130px;
  margin-right: 3px;
  outline: none !important;
  border: none;
}
.date-btn {
  width: 120px;
}
.request__need-wrap {
  position: relative;
  margin-bottom: 27px;
  padding-top: 5px;
  padding-bottom: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: #e5f1f5;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  }

  &--chat {
    margin: 0;
  }
}
.request__need-title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* identical to box height */
  letter-spacing: 0.04em;

  color: #ffffff;

  padding-left: 20px;

  &--dark {
    color: #4b4b67;
  }
}
.request__need-status {
  padding-left: 20px;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #b9dae5;

  &--color {
    color: #b9dae5;
    font-weight: 600;
  }

  &--dark {
    color: #4b4b67;
  }

  &--color-blue {
    color: #1c74fc;
    font-weight: 600;
  }
}
.request-table {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #b9dae5;
}

.request-table td {
  padding-bottom: 10px;
}

.request-table__title {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding-right: 20px;
}
.status-btn-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.status-btn {
  padding: 4px 12px;
  outline: none;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  border-radius: 6px;
  background-color: transparent;

  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #cfcfcf;

  &:not(:last-child) {
    margin-right: 7px;
  }

  &--plus {
    position: relative;
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 0;

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;

      width: 11px;
      height: 11px;

      background-image: url("../../resource/img/grey-plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
}
.status-btn.active {
  background-image: none;
  border: red;
}
.serial_number {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: black;
  font-weight: bold;
}

.__vuescroll {
  height: 100% !important;
}

.modal-button {
  position: relative;
  padding: 10px;
  width: 150px;
  border: 1px solid #006b90;
  letter-spacing: 0.04em;
  color: #006b90;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 0.2em;
  outline: none;
  cursor: pointer;
  .file {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    width: 150px;
    height: 100%;
    margin: 0;
    opacity: 0;
  }
}

.scroll-container-columns-visibility-filter {
  width: 320px;
  overflow: hidden;
  height: calc(100vh - 78px);
  .__vuescroll {
    width: 350px;
  }
}
.status-item {
  display: flex;
  align-items: flex-end;
  .status-item__title {
    position: relative;
    top: 5px;
    display: flex;
    align-items: center;
  }
  .item-title {
    display: flex;
    align-items: center;
    margin: 0 5px 0 10px;
    border-radius: 10px;
    color: #006b90;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    background: #e6f1f4;
  }
  .item-title.not-checked-title {
    color: #fcbc03;
    background: #f2eae7;
  }
  &__checked-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__checked {
    width: 20px;
    height: 20px;
    background: #51a2be;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .checkline {
      position: relative;
      top: -2px;
      width: 30%;
      height: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  }
  .not-checked {
    background: white;
    border-radius: 50%;
    border: 2px solid #fcbc03;
    .checkline {
      position: relative;
      top: -2px;
      width: 30%;
      height: 50%;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(45deg);
    }
  }
  &__line {
    width: 4px;
    height: 20px;
    background: #51a2be;
  }
}
.table-view-settings {
  background: var(--main-card-color);
  border-radius: 6px;
  padding: 20px;
  width: 400px;
  margin: auto auto;
  transform: translateY(5%);
}

.addstage-btn {
  border: 2px solid #51a2be;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 0;
  padding: 0;
  // font-size: 20px;
  color: #51a2be;
  font-weight: 700;
  margin-right: 10px;
  outline: none;
}
.active {
  background: #51a2be !important;
  color: white;
  span {
    color: white !important;
    font-size: 12px;
  }
}
.my-request__item.manager {
  margin-bottom: 0;
  .manager-image {
    width: 60px;
  }
  .my-request__title {
    margin-bottom: 0;
    margin-left: 30px;
    width: 80%;
    font-size: 16px;
    line-height: 27px;
    text-align: start;
  }
}
.my-request__item {
  padding: 10px 0 10px 20px;
  display: flex;
  min-height: 90px;
  max-width: 328px;
  // max-height: 80px;
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  //padding-left: 20px;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  h3 {
    margin-top: 0;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    span {
      font-weight: 700;
    }
    .yellow {
      color: #fbbd3b;
    }
    .blue {
      color: #2378f9;
    }
  }
  .divider {
    width: 0;
    height: 80px;
    background: #e5f1f5;
    border: 3px solid #e5f1f5;
    border-radius: 5px;
    margin: 0 20px 0 10px;
  }

  // &:not(:last-child) {
  //   margin-right: 13px;
  // }
}
.my-request__item.title {
  max-width: 100%;
}
.chat-wrap {
  position: relative;
  display: flex;
  width: 100%;
  //height: 100vh;
  height: calc(100vh - 78px);
  bottom: 10px;
}

.chat {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 10px;
  border: 3px solid #ffffff;
  border-bottom: 0;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  border-radius: 6px;
  padding: 0;
  height: 100%;
}

.chat__title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  padding: 23px 27px 24px 32px;

  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  height: 90px;
}

.chat__list {
  display: flex;
  flex-direction: column-reverse;
  //align-items: flex-end;
  overflow-y: auto;
  margin-bottom: 22px;
  padding: 22px 24px;
  padding-bottom: 0;

  flex: 1 0 auto;
  //max-height: 65%;
  max-height: calc(65% - 152px);
}
.chat__list.manager {
  max-height: 75%;
}

.chat__bottom-panel {
  //position: relative;
  position: sticky;
  top: calc(100vh - 75px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  bottom: 0;
  flex: 0 0 auto;

  padding: 17px 27px;
  background: #ffffff;
  border-radius: 0 0 25px 25px;
  transform: rotate(-180deg);
}

.chat__name {
  display: flex;
  flex-direction: column;
}

.chat__id {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #006b90;
}

.chat__you-msg,
.chat__other-msg {
  padding: 14px 16px 14px 19px;
  border-radius: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  margin-bottom: 12px;
  .file-icon {
    cursor: pointer;
    margin-right: 10px;
  }
}

.check-new-date {
  opacity: 0.5;
}
.chat__you-msg {
  .date-label {
    display: flex;
    justify-content: flex-end;
  }
}
.chat__other-msg {
  .date-label {
    display: flex;
    justify-content: flex-end;
  }
}
.date-label {
  font-size: 10px;
  opacity: 0.5;
}

.timeline-wrapper {
  max-height: 360px;
  overflow-y: auto;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid var(--help-popover-color);
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.pseudo-link {
  cursor: pointer !important;
}

.width-input {
  max-width: 90px;
  margin-bottom: 5px;
}

.text-small {
  font-size: 0.85em;
}
.font-prebold {
  font-weight: 600;
}
</style>
