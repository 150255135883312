<template>
  <TepmplateBlock
    mod-class
    content-class="mytechnique-wrap"
    title-page="Техника"
  >
    <help-popover
      v-if="helpPopovers.PopoverOfTitlePage"
      :message="helpPopovers.PopoverOfTitlePage"
      class="ml-2 help-icon"
      :is-inline-block="false"
      style="right: calc(50% + 55px); top: 15px"
    />

    <template #titleAppend>
      <IconPlus @clicked="createTechnique" />
      <help-popover
        v-if="helpPopovers.PopoverOfIconPlus"
        :message="helpPopovers.PopoverOfIconPlus"
        class="ml-2 help-icon"
        :is-inline-block="false"
        style="right: calc(50% + 15px); top: 10px"
      />
    </template>

    <div class="scroll-container d-flex flex-column">
      <div class="filters-block position-relative w-100">
        <div class="filters-visibility-toggle">
          <img
            v-if="!isShowFilterInfo"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/UlEQVR4nO2R3UoCURSFVz/mqwRdhBKSQo/hK4TtPT5CHCFvnb1HCPpBMQgEEaK3qCfRIuz3fsJxGA8JeqiBEOa73Gettdc5B8jIWENqwSFIuvBaJWcPSxmsNzgJKqvFpI9gDcHy6WTw/COQfs08+uDSphGLQ5C8L73JrPnHXK+nqxcg3ADLeWJifQP7BwuyelAAy8TSXUVeN6ZL9MJq9gr2i8mx194HyYsV3oExm47h1hLSa+u5nkH+Hrz2LkjG1rz3i/CY6mALJP15UxmB9clqfhtp/kT155Kk+RDGbCMVji9zIL23/uQumqVKPciDtAnWMxizk254Rsa/8g0pQIzaX6HRyAAAAABJRU5ErkJggg=="
            @click="toggleIsShowFilterInfo"
          />
          <img
            v-else
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABG0lEQVR4nGNgGAWjYADAf0bamJvVp82Q3f+AIXvCfYbMfk3qGp43QZUhe8IzhuwJ/8E4q/8F9SzJ6lVhyJ7wBG44wpLHDLkTlSkzPL1fgSGr/yHC0AkbGbInbEFY1P+AIWuKPHmG506UYciecBfJsJ0M8fM5GOrr2Si3JG+COENW/3Ukl+9mKOzlhMuD2FkT9iIF1y2G7MlSxBme0S3GkNV/FcmFhxmypvBgqEubycWQNWE/UrzcYMiaIoHf8OzJwgzZE64QNBwGkjp5GbImHEPy6SWGzGmCDDhB1oQqJG8fZ8idyMdACKR18DNk959CclQ5bsUZEy0YsvrfMGT372PI7xcgaDgMgFwN9u2E9wzZ/ZZE6xsFo2BwAABuJZjduC14NAAAAABJRU5ErkJggg=="
            @click="toggleIsShowFilterInfo"
          />
        </div>

        <div v-if="!isShowFilterInfo" class="filters-row w-100 pr-5 pl-1 pt-1">
          <div class="d-flex align-items-center justify-content-between">
            <div class="left-side d-flex align-items-center">
              <div
                v-if="!this.sortByTags"
                @click="toggleCheckedMode"
                class="c-pointer form-mytechnique__sort-wrap mr-4 mb-4"
              >
                <button
                  class="form-mytechnique__sort-btn"
                  :class="{
                    'form-mytechnique__sort-btn_active': isCheckedMode === true
                  }"
                >
                  <span
                    >Выбрать
                    <help-popover
                      v-if="helpPopovers.PopoverOfChooseButton"
                      :message="helpPopovers.PopoverOfChooseButton"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 110px"
                    ></help-popover
                  ></span>
                </button>
              </div>

              <div
                @click="onSortClick"
                class="form-mytechnique__sort-wrap mb-4"
                :class="{ sorted: sortByTags }"
              >
                <button
                  class="form-mytechnique__sort-btn"
                  :class="{
                    'form-mytechnique__sort-btn_active': sortByTags === true
                  }"
                >
                  <span
                    >Показать по тегам
                    <help-popover
                      v-if="helpPopovers.PopoverOfChooseButtonTags"
                      :message="helpPopovers.PopoverOfChooseButtonTags"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 37px"
                    ></help-popover
                  ></span>
                </button>
              </div>
            </div>
            <div class="right-side d-flex align-items-center">
              <div
                v-if="!this.sortByTags"
                @click="selectAllVisibleItems"
                class="c-pointer form-mytechnique__sort-wrap mb-4"
              >
                <button class="form-mytechnique__sort-btn">
                  <span
                    >Выбрать все
                    <help-popover
                      v-if="helpPopovers.PopoverOfChooseAllButton"
                      :message="helpPopovers.PopoverOfChooseAllButton"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 110px"
                    ></help-popover
                  ></span>
                </button>
              </div>
              <div
                v-if="!this.sortByTags && selectedItems.length"
                class="c-pointer form-mytechnique__sort-wrap mr-4 mb-4 d-flex align-items-center"
              >
                <button
                  class="form-mytechnique__sort-btn deselect-all"
                  @click="deselectAllVisibleItems"
                >
                  <span
                    >Снять выделение co всех
                    <help-popover
                      v-if="helpPopovers.PopoverOfDeselectAllButton"
                      :message="helpPopovers.PopoverOfDeselectAllButton"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 110px"
                    ></help-popover
                  ></span>
                </button>
                <img
                  title="Назначить наблюдателя"
                  width="32"
                  height="32"
                  style="margin-top: 20px;"
                  @click="showAddViewerModal"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAETUlEQVR4nO2bTYwURRTH/yNKTMR4MH6EgyeMB6OJovGCR+Uj4WICETFZwmFDvxrW4AJ6IJmTCWaZfq+zexASQkQUwXjjyk1jorhqMMZEOBhAMCQeEA6gOKaqdnurZ3q2Z7anZ6ea/iV1mKnqmnr18epfr3qAioqKioqKioqKiiHRqiGI1oLC7SDZY1O4HSp80eSVFpp5EsQC4itQ0kpNOo+EMSFPoDy0aqjLfpDc7Gp4R+K/oaK9/s+IsWMPgvhkioFXzfckTSgObRm+mtIZn5k6/KRV6zSezyGIXkejcV9Hcf2d4vUgnu3oBC9nQl32txkfYsvpFZnPNRr3G1+R8A0yCa/YNfU4lNxwDGj2XYeSKOETtBP1BnJHkM/1NPLpM8FZDhzCo7V/JW64XvNLhaINzlK47IcvUOFLTqP/SHV4vaKfJb62UJ8WS6OOkrccYXMyf318yunQNzHyqGiv0wFT+evjQ37tBiSTjuM6NID6mk5972LkId52by+BIFqbkLx5naCSP+P6dkcvwLttkKINS66KZJMzmy75sQ1q9JF2oeGzRtQsTQj9mEtNjo4UZum7DsXTCSnsXYxAOduhTVFPM0GXSRhvRn8P/KNVM0fZZMRn1viEbsdhvebdaW/TCX/Wfjs6mNHeCbYjroHkc3PAselUwtsvlPvU44CIuytocaTDXL2GxOSGnfa+jnwa2omZ0ZbLiwRFLxlvr51oeWnVjKDRijEOi/M2K3LKNOIVFRUdNBorEcjLoGgrVDQOxe/ZJO9Yn8CvYOeHD6NUqOZzUPwBSL4Dye0eboX+heKfTCyhHr7qp2McP/wAiHdCyc997P3d0kfwirq8DSUXu4zuf1DyK5R8CeLDIDloktUHXxgZTPJP23O/wAvU9GooOZNitFaAJxCEmzF+8JHMeianHkKdN0LJURB/g4DfwMij5DUo/qvN+OvGwdHMKpQaJWNQfMc5ut41AZGiDZ+Qp0F8AIq/nnuv4LaNRJnPB0x+4RAHc+t6Xsv/bj13gajpR6HkSIqvaDtXmPwjpnwhBOFmu2XFP/qD8QNFQuGz3R1s13TRPDdQAnk+8caHdlZFC5gJeSp5TdZH0s/p5we4x886P/AbdkePoUj07bK+Zc6jJ7QQyxOijzGOJ97ibmEXP4OiId6RMcL77PbZ/lJGR7kd+RrSMK+x3Bp6sFLx+UUN02cM276VGTL7fP7GkHw/Z/zZwUypDPR2ljW9XTKXQnNNvgbRzCqQrBtasNK9a3S1huLjsZROlI/ltc6/m9IJHtwvdr1tjtPH6AXdCZ3LwIMb5swO4OPoBeJPStABnL4EjHFzARWX+e90fjmWQHPNQJ3gUM4Ipd4Gl4MsIaSnvBZCJO9nzIAxeMmW0yuMlM2a3ot30rdD0S2FoU+ai/3XYGiHoeVEH2uJL/Rp/IXBH4eXk34CIjrwWlhAZLmZD4mRfJUIidnPQwqJwX/+B3Dv75D43fdLAAAAAElFTkSuQmCC"
                />
              </div>
              <div
                v-else
                class="form-mytechnique__sort-wrap mr-4 mb-4"
                style="min-width: 240px;"
              >
                &nbsp;
              </div>
              <!-- <div class="d-flex align-items-center form-toggler mr-2">
                  <div
                    id="grid-view"
                    class="form-toggler__item"
                    :class="{ active: listView === 'grid' }"
                    @click="toggleListView('grid')"
                  ></div>
                  <div
                    id="table-view"
                    class="form-toggler__item"
                    :class="{ active: listView === 'table' }"
                    @click="toggleListView('table')"
                  ></div>
                </div> -->
              <div class="d-flex align-items-center form-toggler">
                <div
                  id="grid-view"
                  class="form-toggler__item"
                  :class="{ active: listView === 'grid' }"
                  @click="toggleListView('grid')"
                ></div>
                <div
                  id="table-view"
                  class="form-toggler__item"
                  :class="{ active: listView === 'table' }"
                  @click="toggleListView('table')"
                ></div>
                <div
                  class="columns-visibility-settings-toggler"
                  @click="isColumnsVisibilityChooserModalVisible = true"
                >
                  <img
                    v-if="listView === 'table'"
                    height="21"
                    class="ml-1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nM1VvWoVURD+NAr6BDbiP4qgYCXYaDRFHkBuEYXENMaduQloabOFrbszVzTRSgiJQpqooEHBB9BHsPKn1VJtlMjM2XvcveveezW3cGDhnDkz883/Av8NJZ1zYH0UPhkfvXHSn2B5D5IPfjbeP9OcHADLJBZ0j9/NazM883CXf6wfQbLqb+07B0H5ZbCcGc44yw2w/ADrJli+guUeSL+A9HWUsbPzZAks34Osf/eH8dyM3wLfPgqW565I+gbt/EIJYMJ54e0FEjkO0tTv7ex0P+8nXeiaHPN7a20MSX6oUX6+cxhpusPPpuOAMtUMYDkPadmIisOQyZoO6Tcs6L7+wqyL7ol5F2lzG0iugOQxWJ+BJMHVBzvjs8mGGiyikZLO+aJbPoPlbeSn6XaQPi3Cf+dfOL+qgFhNXPdPc2LGrbetFVleVgrKOlMYTH7LKxW8uRLAhIN6C/vMlEDKfd5LLMvudS95JPKkxr+e7a7MSQQwpj3WAHRlBAAyXqTok4dp4cZ0yLSnw9IS5XMuijrbmKLaKukus94iwzpI1+tF1nV/6y2yeU5ythbZwDZNZDooO9hsxXi3TW119CUbEhsWG3+b4r8dNNtLdHd/s2BbW2FV6Am/t9bGqpH0WRWUHSnqcqkZgORUsUVzcHbSI+kuu3Lh7Wx1CrIbbpz05uBlF5SzuH7J0iVLjeva6mUyQ6/raiQXMd/ZWxvEbp8bL9ZNpgZ73o+4mJNguKHPt0r+X/YeXx298S3QL1BXflHRxQAyAAAAAElFTkSuQmCC"
                    title="Настроить видимость колонок таблицы"
                  />
                  <div
                    v-if="listView === 'grid'"
                    class="ml-1"
                    style="width: 21px; height: 21px;"
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="filters-row w-100 pr-5 pl-1 pt-1">
          <div class="d-flex">
            <div class="mr-4">
              <div>
                <label
                  >Поиск
                  <help-popover
                    v-if="helpPopovers.PopoverOfSearch"
                    :message="helpPopovers.PopoverOfSearch"
                    class="ml-2 help-icon"
                    :is-inline-block="false"
                    style="left: 60px; bottom: 10px"
                /></label>
              </div>
              <div>
                <input
                  @keydown="onKeyDown"
                  v-model="searchString"
                  class="form-control form-control-sm form-mytechnique__search"
                  type="text"
                  placeholder="Поиск по модели/номеру"
                  aria-label="Search"
                />
              </div>
            </div>

            <div class="ml-4 mr-4">
              <div><label>Компания</label></div>
              <b-select
                @change="searchByCompany"
                v-model="checkedCompany"
                class="form-control form-control-sm form-mytechnique__search"
              >
                <option
                  v-for="(company, index) in concatCompanies"
                  :value="company.id"
                  :key="index"
                  >{{ company.brand }}
                </option>
              </b-select>
            </div>

            <div class="ml-4 mr-4">
              <label
                >Офис
                <help-popover
                  v-if="helpPopovers.PopoverOfOffice"
                  :message="helpPopovers.PopoverOfOffice"
                  class="ml-2 help-icon"
                  :is-inline-block="false"
                  style="left: 55px"
                /> </label
              ><br />

              <b-select
                @change="searchDevices"
                v-model="checkedOffices"
                class="form-control form-control-sm form-mytechnique__search"
              >
                <option :value="null">Выберите офис</option>
                <option
                  v-for="office in allOffices"
                  :value="office.id"
                  :key="office.id"
                  >{{ office.name }}</option
                >
              </b-select>
            </div>

            <div
              v-if="selectedItems.length && canPrintQr"
              class="d-flex  align-items-end justify-content-end"
            >
              <button
                type="button"
                class="btn btn-primary mr-1"
                @click="printQrForSomeTechnique"
              >
                Печать QrCode
              </button>
              <button
                type="button"
                class="btn btn-primary ml-1"
                title="Показать информацию о выбранной технике"
                @click="isSelectedDevicesInfoVisible = true"
              >
                Выбрано: {{ selectedItems.length }}
              </button>
            </div>
          </div>
          <div class="myclass" v-if="isShowFilterInfo">
            <div class="box align-items-center">
              <div>
                <div
                  class="c-pointer form-mytechnique__sort-wrap mr-4"
                  @click="changeModeFilterCountersSum"
                >
                  <button
                    class="form-mytechnique__sort-btn mb-1"
                    :class="{
                      'form-mytechnique__sort-btn_active':
                        isFilterCountersSum === true
                    }"
                  >
                    <span
                      >Напечатанные страницы
                      <help-popover
                        :message="helpPopovers.PopoverOfPrintedPages"
                        class="ml-2 help-icon"
                        :is-inline-block="false"
                        style="left: 200px"
                      />
                    </span>
                  </button>
                </div>
                <div class="box">
                  <div
                    style="padding-right: 20px; display: flex; align-items: end;"
                  >
                    <label class="mr-1">от</label>
                    <input
                      size="6"
                      @keydown="onKeyDown"
                      v-model="countersSumMin"
                      class="form-control form-control-sm form-mytechnique__search analiticsParams"
                      type="text"
                      placeholder="мин"
                    />
                  </div>
                  <div
                    style="padding-right: 20px; display: flex; align-items: end;"
                  >
                    <label class="mr-1">до</label>
                    <input
                      size="6"
                      @keydown="onKeyDown"
                      v-model="countersSumMax"
                      class="form-control form-control-sm form-mytechnique__search analiticsParams"
                      type="text"
                      placeholder="макс"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div
                  class="c-pointer form-mytechnique__sort-wrap mr-4"
                  @click="changeModeFilterCostPerPage"
                >
                  <button
                    class="form-mytechnique__sort-btn mb-1"
                    :class="{
                      'form-mytechnique__sort-btn_active':
                        isFilterCostPerPage === true
                    }"
                  >
                    <span
                      >Стоимость 1-й страницы
                      <help-popover
                        :message="helpPopovers.PopoverOfPrintedPages"
                        class="ml-2 help-icon"
                        :is-inline-block="false"
                        style="left: 200px"
                      />
                    </span>
                  </button>
                </div>
                <div class="box">
                  <label class="mr-1">от</label>
                  <input
                    size="6"
                    @keydown="onKeyDown"
                    v-model="costPerPageMin"
                    class="form-control form-control-sm form-mytechnique__search analiticsParams"
                    type="text"
                    placeholder="мин"
                  />

                  <label class="mr-1">до</label>
                  <input
                    size="6"
                    @keydown="onKeyDown"
                    v-model="costPerPageMax"
                    class="form-control form-control-sm form-mytechnique__search analiticsParams"
                    type="text"
                    placeholder="макс"
                  />
                </div>
              </div>

              <div>
                <div
                  class="c-pointer form-mytechnique__sort-wrap mr-4"
                  @click="changeModeFilterExpenses"
                >
                  <button
                    class="form-mytechnique__sort-btn mb-1"
                    :class="{
                      'form-mytechnique__sort-btn_active':
                        isFilterExpenses === true
                    }"
                  >
                    <span
                      >Затраты на аппарат
                      <help-popover
                        :message="helpPopovers.PopoverOfPrintedPages"
                        class="ml-2 help-icon"
                        :is-inline-block="false"
                        style="left: 200px"
                      />
                    </span>
                  </button>
                </div>
                <div class="box">
                  <label class="mr-1">от</label>
                  <input
                    size="6"
                    @keydown="onKeyDown"
                    v-model="expensesMin"
                    class="form-control form-control-sm form-mytechnique__search analiticsParams"
                    type="text"
                    placeholder="мин"
                  />

                  <label class="mr-1">до</label>
                  <input
                    size="6"
                    @keydown="onKeyDown"
                    v-model="expensesMax"
                    class="form-control form-control-sm form-mytechnique__search analiticsParams"
                    type="text"
                    placeholder="макс"
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                class="tasks-action-buttons d-flex align-items-end mt-3 justify-content-start flex-grow-1"
                :class="{ 'mb-3': !isShowFilterInfo }"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="btn btn-second add-item__btn"
                    @click="applyFilters"
                  >
                    Применить фильтр
                  </div>
                  <div class="clear ml-3" @click="clearFilter">
                    Очистить
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="left-side d-flex align-items-center">
              <div
                v-if="!this.sortByTags"
                @click="toggleCheckedMode"
                class="c-pointer form-mytechnique__sort-wrap mr-4 mb-4"
              >
                <button
                  class="form-mytechnique__sort-btn"
                  :class="{
                    'form-mytechnique__sort-btn_active': isCheckedMode === true
                  }"
                >
                  <span
                    >Выбрать
                    <help-popover
                      v-if="helpPopovers.PopoverOfChooseButton"
                      :message="helpPopovers.PopoverOfChooseButton"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 110px"
                    ></help-popover
                  ></span>
                </button>
              </div>

              <div
                @click="onSortClick"
                class="form-mytechnique__sort-wrap mb-4"
                :class="{ sorted: sortByTags }"
              >
                <button
                  class="form-mytechnique__sort-btn"
                  :class="{
                    'form-mytechnique__sort-btn_active': sortByTags === true
                  }"
                >
                  <span
                    >Показать по тегам
                    <help-popover
                      v-if="helpPopovers.PopoverOfChooseButtonTags"
                      :message="helpPopovers.PopoverOfChooseButtonTags"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 37px"
                    ></help-popover
                  ></span>
                </button>
              </div>
            </div>
            <div class="right-side d-flex align-items-center">
              <div
                v-if="!this.sortByTags && selectedItems.length"
                class="c-pointer form-mytechnique__sort-wrap mr-4 mb-4 d-flex align-items-center"
              >
                <button
                  class="form-mytechnique__sort-btn deselect-all"
                  @click="deselectAllVisibleItems"
                >
                  <span
                    >Снять выделение co всех
                    <help-popover
                      v-if="helpPopovers.PopoverOfDeselectAllButton"
                      :message="helpPopovers.PopoverOfDeselectAllButton"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 110px"
                    ></help-popover
                  ></span>
                </button>
                <img
                  title="Назначить наблюдателя"
                  width="32"
                  height="32"
                  style="margin-top: 20px;"
                  @click="showAddViewerModal"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAETUlEQVR4nO2bTYwURRTH/yNKTMR4MH6EgyeMB6OJovGCR+Uj4WICETFZwmFDvxrW4AJ6IJmTCWaZfq+zexASQkQUwXjjyk1jorhqMMZEOBhAMCQeEA6gOKaqdnurZ3q2Z7anZ6ea/iV1mKnqmnr18epfr3qAioqKioqKioqKiiHRqiGI1oLC7SDZY1O4HSp80eSVFpp5EsQC4itQ0kpNOo+EMSFPoDy0aqjLfpDc7Gp4R+K/oaK9/s+IsWMPgvhkioFXzfckTSgObRm+mtIZn5k6/KRV6zSezyGIXkejcV9Hcf2d4vUgnu3oBC9nQl32txkfYsvpFZnPNRr3G1+R8A0yCa/YNfU4lNxwDGj2XYeSKOETtBP1BnJHkM/1NPLpM8FZDhzCo7V/JW64XvNLhaINzlK47IcvUOFLTqP/SHV4vaKfJb62UJ8WS6OOkrccYXMyf318yunQNzHyqGiv0wFT+evjQ37tBiSTjuM6NID6mk5972LkId52by+BIFqbkLx5naCSP+P6dkcvwLttkKINS66KZJMzmy75sQ1q9JF2oeGzRtQsTQj9mEtNjo4UZum7DsXTCSnsXYxAOduhTVFPM0GXSRhvRn8P/KNVM0fZZMRn1viEbsdhvebdaW/TCX/Wfjs6mNHeCbYjroHkc3PAselUwtsvlPvU44CIuytocaTDXL2GxOSGnfa+jnwa2omZ0ZbLiwRFLxlvr51oeWnVjKDRijEOi/M2K3LKNOIVFRUdNBorEcjLoGgrVDQOxe/ZJO9Yn8CvYOeHD6NUqOZzUPwBSL4Dye0eboX+heKfTCyhHr7qp2McP/wAiHdCyc997P3d0kfwirq8DSUXu4zuf1DyK5R8CeLDIDloktUHXxgZTPJP23O/wAvU9GooOZNitFaAJxCEmzF+8JHMeianHkKdN0LJURB/g4DfwMij5DUo/qvN+OvGwdHMKpQaJWNQfMc5ut41AZGiDZ+Qp0F8AIq/nnuv4LaNRJnPB0x+4RAHc+t6Xsv/bj13gajpR6HkSIqvaDtXmPwjpnwhBOFmu2XFP/qD8QNFQuGz3R1s13TRPDdQAnk+8caHdlZFC5gJeSp5TdZH0s/p5we4x886P/AbdkePoUj07bK+Zc6jJ7QQyxOijzGOJ97ibmEXP4OiId6RMcL77PbZ/lJGR7kd+RrSMK+x3Bp6sFLx+UUN02cM276VGTL7fP7GkHw/Z/zZwUypDPR2ljW9XTKXQnNNvgbRzCqQrBtasNK9a3S1huLjsZROlI/ltc6/m9IJHtwvdr1tjtPH6AXdCZ3LwIMb5swO4OPoBeJPStABnL4EjHFzARWX+e90fjmWQHPNQJ3gUM4Ipd4Gl4MsIaSnvBZCJO9nzIAxeMmW0yuMlM2a3ot30rdD0S2FoU+ai/3XYGiHoeVEH2uJL/Rp/IXBH4eXk34CIjrwWlhAZLmZD4mRfJUIidnPQwqJwX/+B3Dv75D43fdLAAAAAElFTkSuQmCC"
                />
              </div>
              <div
                v-if="!this.sortByTags"
                @click="selectAllVisibleItems"
                class="c-pointer form-mytechnique__sort-wrap mb-4"
              >
                <button class="form-mytechnique__sort-btn">
                  <span
                    >Выбрать все
                    <help-popover
                      v-if="helpPopovers.PopoverOfChooseAllButton"
                      :message="helpPopovers.PopoverOfChooseAllButton"
                      class="ml-2 help-icon"
                      :is-inline-block="false"
                      style="right: 110px"
                    ></help-popover
                  ></span>
                </button>
              </div>
              <!-- <div class="d-flex align-items-center form-toggler mr-2">
                  <div
                    id="grid-view"
                    class="form-toggler__item"
                    :class="{ active: listView === 'grid' }"
                    @click="toggleListView('grid')"
                  ></div>
                  <div
                    id="table-view"
                    class="form-toggler__item"
                    :class="{ active: listView === 'table' }"
                    @click="toggleListView('table')"
                  ></div>
                </div> -->
              <div class="d-flex align-items-center form-toggler">
                <div
                  id="grid-view"
                  class="form-toggler__item"
                  :class="{ active: listView === 'grid' }"
                  @click="toggleListView('grid')"
                ></div>
                <div
                  id="table-view"
                  class="form-toggler__item"
                  :class="{ active: listView === 'table' }"
                  @click="toggleListView('table')"
                ></div>
                <div
                  class="columns-visibility-settings-toggler"
                  @click="isColumnsVisibilityChooserModalVisible = true"
                >
                  <img
                    v-if="listView === 'table'"
                    height="21"
                    class="ml-1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nM1VvWoVURD+NAr6BDbiP4qgYCXYaDRFHkBuEYXENMaduQloabOFrbszVzTRSgiJQpqooEHBB9BHsPKn1VJtlMjM2XvcveveezW3cGDhnDkz883/Av8NJZ1zYH0UPhkfvXHSn2B5D5IPfjbeP9OcHADLJBZ0j9/NazM883CXf6wfQbLqb+07B0H5ZbCcGc44yw2w/ADrJli+guUeSL+A9HWUsbPzZAks34Osf/eH8dyM3wLfPgqW565I+gbt/EIJYMJ54e0FEjkO0tTv7ex0P+8nXeiaHPN7a20MSX6oUX6+cxhpusPPpuOAMtUMYDkPadmIisOQyZoO6Tcs6L7+wqyL7ol5F2lzG0iugOQxWJ+BJMHVBzvjs8mGGiyikZLO+aJbPoPlbeSn6XaQPi3Cf+dfOL+qgFhNXPdPc2LGrbetFVleVgrKOlMYTH7LKxW8uRLAhIN6C/vMlEDKfd5LLMvudS95JPKkxr+e7a7MSQQwpj3WAHRlBAAyXqTok4dp4cZ0yLSnw9IS5XMuijrbmKLaKukus94iwzpI1+tF1nV/6y2yeU5ythbZwDZNZDooO9hsxXi3TW119CUbEhsWG3+b4r8dNNtLdHd/s2BbW2FV6Am/t9bGqpH0WRWUHSnqcqkZgORUsUVzcHbSI+kuu3Lh7Wx1CrIbbpz05uBlF5SzuH7J0iVLjeva6mUyQ6/raiQXMd/ZWxvEbp8bL9ZNpgZ73o+4mJNguKHPt0r+X/YeXx298S3QL1BXflHRxQAyAAAAAElFTkSuQmCC"
                    title="Настроить видимость колонок таблицы"
                  />
                  <div
                    v-if="listView === 'grid'"
                    class="ml-1"
                    style="width: 21px; height: 21px;"
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   <div v-if="this.concatCompanies && this.concatCompanies.length > 1">
          <div v-if="allDevices.length" class="mytechnique row w-100" id="grid">
            <div
              class="mytechnique__item mb-3"
              v-for="item in allDevices"
              :key="item.text"
            >
              <router-link class="mytechnique__link" :to="item.link">
                <img
                  :src="
                    item.picture ? item.picture : 'https://placehold.it/90x80'
                  "
                  alt=""
                  class="mytechnique__img"
                />

                <h3 class="mytechnique__title">{{ item.title }}</h3>

                <p class="mytechnique__model">{{ item.serialNum }}</p>

                <p class="mytechnique__model">{{ item.stickerNum }}</p>


              </router-link>
            </div>
          </div>

          <div v-else>
            Устройств нет
          </div>
        </div> -->

      <div v-if="this.concatCompanies" class="flex-1 overflow-hidden">
        <!-- && this.concatCompanies.length === 1 -->

        <vuescroll @handle-scroll="handleScroll">
          <div
            class="d-flex justify-content-center mb-5 mt-5"
            v-if="isShowSpinner"
          >
            <b-spinner />
          </div>

          <template v-else>
            <template v-if="listView === 'grid'">
              <div
                v-if="sortByTags === false"
                class="mytechnique row w-100"
                id="grid"
              >
                <div
                  class="mytechnique__item mb-3"
                  v-for="item in items"
                  :key="item.text"
                  :class="[isItemChecked(item) ? 'item-selected' : '']"
                >
                  <router-link
                    class="mytechnique__link"
                    :to="item.link"
                    v-if="!isCheckedMode"
                  >
                    <img
                      :src="getImageDeviceOrTechnique(item)"
                      alt=""
                      class="mytechnique__img"
                    />

                    <h3
                      class="mytechnique__title"
                      v-html="
                        $options.filters.highlight(item.title, searchString)
                      "
                    ></h3>
                    <p
                      class="mytechnique__model"
                      v-html="
                        $options.filters.highlight(item.serialNum, searchString)
                      "
                    ></p>
                    <!-- pril при загрузке страницы -->
                    <p
                      class="mytechnique__model"
                      v-html="
                        $options.filters.highlight(
                          item.stickerNum,
                          searchString
                        )
                      "
                    ></p>
                    <p
                      class="mytechnique__model"
                      v-html="
                        $options.filters.highlight(
                          item.specialMarks,
                          searchString
                        )
                      "
                    ></p>
                    <!--    <div
                        class="mytechnique__status"
                        v-bind:class="{
                          mytechnique__status_green: item.statusColor === 1,
                          mytechnique__status_blue: item.status === 3,
                          mytechnique__status_orange: item.status === 2
                        }"
                      >
                        <span class="mytechnique__status-text">{{
                          devicesStatus[item.status]
                        }}</span>
                      </div> -->
                  </router-link>

                  <div
                    class="mytechnique__link c-pointer"
                    v-else
                    @click="selectItem(item)"
                  >
                    <!-- <img
                      :src="
                          item.picturePath
                            ? picture(item.picturePath)
                            : pictureServerorEmpty(item.url)
                        "
                        alt=""
                        class="mytechnique__img"
                      /> -->
                    <img
                      :src="getImageDeviceOrTechnique(item)"
                      alt=""
                      class="mytechnique__img"
                    />
                    <h3
                      class="mytechnique__title"
                      v-html="
                        $options.filters.highlight(item.title, searchString)
                      "
                    ></h3>
                    <p
                      class="mytechnique__model"
                      v-html="
                        $options.filters.highlight(item.serialNum, searchString)
                      "
                    ></p>
                    <!-- ***  -->
                    <p
                      class="mytechnique__model"
                      v-html="
                        $options.filters.highlight(
                          item.stickerNum,
                          searchString
                        )
                      "
                    ></p>
                    <!--     <div
                        class="mytechnique__status"
                        v-bind:class="{
                          mytechnique__status_green: item.statusColor === 1,
                          mytechnique__status_blue: item.status === 3,
                          mytechnique__status_orange: item.status === 2
                        }"
                      >
                        <span class="mytechnique__status-text">{{
                          devicesStatus[item.status]
                        }}</span>
                      </div> -->

                    <span class="d-flex justify-content-center fake-link small"
                      >Выбрать</span
                    >
                  </div>
                </div>
                <div v-if="!items.length && !isShowSpinner">
                  Теги геолокации в устройствах не указаны.
                </div>
              </div>

              <div v-else class="d-flex mt-3 flex-wrap w-100">
                <div
                  v-for="(value, name) in tagObject"
                  :key="name"
                  class="my-3 mr-5 w-100"
                >
                  <span class="tagname" @click="showItems(name)">
                    <span class="tagname__check" :class="openOrClose(name)">
                    </span>
                    {{ name }}
                  </span>
                  <div class="mytechnique row mt-3 " v-if="checkShow(name)">
                    <div
                      class="mytechnique__item mt-3"
                      v-for="item in value"
                      :key="item.text"
                    >
                      <router-link class="mytechnique__link" :to="item.link">
                        <!-- <img
                            :src="
                              item.picture !== null
                                ? item.picture
                                : 'https://placehold.it/90x80'
                            "
                            alt=""
                            class="mytechnique__img"
                          /> -->
                        <img
                          :src="getImageDeviceOrTechnique(item)"
                          alt=""
                          class="mytechnique__img"
                        />

                        <h3
                          class="mytechnique__title"
                          v-html="
                            $options.filters.highlight(
                              item.setialNum,
                              searchString
                            )
                          "
                        ></h3>
                        <p
                          class="mytechnique__model"
                          v-html="
                            $options.filters.highlight(
                              item.stickerNum,
                              searchString
                            )
                          "
                        ></p>
                        <!-- *** -->
                        <p class="mytechnique__model">{{ item.stickerNum }}</p>

                        <!-- <div
                            class="mytechnique__status"
                            v-bind:class="{
                              mytechnique__status_green: item.statusColor === 1,
                              mytechnique__status_blue: item.status === 3,
                              mytechnique__status_orange: item.status === 2
                            }"
                          >
                            <span class="mytechnique__status-text">{{
                              devicesStatus[item.status]
                            }}</span>
                          </div> -->
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <table
                v-if="sortedColumnsArray.length"
                class="table b-table table-bordered table-striped"
                :key="columnsVisibilityKey"
              >
                <thead>
                  <tr>
                    <th>
                      <b-form-checkbox
                        :checked="
                          this.items.length &&
                            this.selectedItems.length === this.items.length
                        "
                        @change="toggleSelectAll"
                      ></b-form-checkbox>
                    </th>
                    <th
                      v-for="key in sortedColumnsArray"
                      :key="key"
                      v-show="columnsVisibility[key].value"
                      :class="{
                        'pseudo-link': columnsVisibility[key].sortable
                      }"
                      @click="setSorting(key)"
                    >
                      <img
                        v-if="sortType === key && sortOrder === 'ASC'"
                        class="mr-1"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQElEQVR4nGNgGAWEQBYUkwWKGBgY/kNxFSWa/5NqSBEWzUQbUoRHM0FDCojQDMMgtRiggYGBYRWRGKR2FFALAAArwCrr3qu96AAAAABJRU5ErkJggg=="
                      />
                      <img
                        v-else-if="sortType === key && sortOrder === 'DESC'"
                        class="mr-1"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaUlEQVR4nO3PoQ2DUBQF0DMAqWAKmlRgMF2CRWCqqnagCtRfgtBgICQIxC+fVnOS6+59yeO0dcUTr0SWTiEiR8CUSFi7URU+O+MRdwntzoHGQY/IePn9sAzvzbjDxY9u6DGg9Kd6zcl3M2zuK/S5fVJJAAAAAElFTkSuQmCC"
                      />
                      <img
                        v-else-if="
                          sortType !== key && columnsVisibility[key].sortable
                        "
                        class="mr-1"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVR4nM2P0QmAMAxEH7iFOIYjuJAiqBs4gns4jXSYSuE+pDS2xR/v55qEe0nhb2qBU57SkgtfgJenIHsuvAqwGhDzgkMhBED1UXpB83h7o/96AQagZvYjQKwB6CJAp37RlglwCnm5U59SzQoFQPBQm7L+OWq21WyO1X8JJ3UDta8hfWBA/RwAAAAASUVORK5CYII="
                      />
                      <span>{{ columnsVisibility[key].title }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id" class="item-row">
                    <td>
                      <b-form-checkbox
                        :checked="isItemChecked(item)"
                        @change="selectItem(item)"
                      />
                    </td>
                    <td
                      v-for="key in sortedColumnsArray"
                      :key="key"
                      v-show="columnsVisibility[key].value"
                    >
                      <template v-if="key === 'img'">
                        <img
                          :src="getImageDeviceOrTechnique(item)"
                          alt=""
                          class="mytechnique__img"
                        />
                      </template>
                      <template v-else-if="key === 'title'">
                        <h3
                          v-html="
                            $options.filters.highlight(item[key], searchString)
                          "
                          is="router-link"
                          :to="item.link"
                        ></h3>
                      </template>
                      <template v-else-if="key === 'viewer'">
                        <template v-if="!item.viewers.length">
                          <span>-</span>
                        </template>
                        <template v-else>
                          <span
                            v-for="viewer in item.viewers"
                            :key="viewer.id"
                            class="mr-3"
                          >
                            {{ viewer.firstName }} {{ viewer.lastName }}
                          </span>
                        </template>
                      </template>
                      <template v-else>
                        <span>{{ item[key] || "-" }}</span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </template>
        </vuescroll>
      </div>
    </div>

    <plus-button
      class=""
      :devices="selectedItems"
      v-if="isCheckedMode"
      modal-title="Создать задачу"
      ref="refCreateOrder"
      @add-order="orderCreated"
    >
    </plus-button>

    <router-link to="/add-new-item" v-else>
      <b-button class="plus-btn" title="Добавление нового устройства">
        <help-popover
          v-if="helpPopovers.PopoverOfPlusButton"
          :message="helpPopovers.PopoverOfPlusButton"
          class="ml-2 help-icon"
          :is-inline-block="false"
          style="left: 60px"
        ></help-popover>
      </b-button>
    </router-link>

    <b-modal id="qr-start-position-select-modal" hide-footer>
      <template #modal-title>
        Печать QrCode
      </template>
      <div class="d-block">
        <template v-if="isNeoPharm">
          <b-form-group label="Введите URL для QR-кода" label-for="qr-url">
            <b-form-input
              v-model="currentQrURL"
              id="qr-url"
              type="text"
              v-on:keyup.enter="doApplyPrintQr"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Введите название компании для QR-кода"
            label-for="qr-url"
          >
            <b-form-input
              v-model="currentQrName"
              id="qr-url"
              type="text"
              v-on:keyup.enter="doApplyPrintQr"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Введите телефон компании для QR-кода"
            label-for="qr-url"
          >
            <b-form-input
              v-model="currentQrPhone"
              id="qr-url"
              type="text"
              v-phone
              v-on:keyup.enter="doApplyPrintQr"
            ></b-form-input>
          </b-form-group>
        </template>
        <b-form-group
          label="Введите стартовую позицию наклейки (0 - 17)"
          label-for="start-position-number"
        >
          <b-form-input
            v-model="currentQrStartPosition"
            id="start-position-number"
            type="number"
            min="0"
            max="17"
            autofocus
            v-on:keyup.enter="doApplyPrintQr"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-end">
        <button class="btn btn-second" @click="doApplyPrintQr">
          <span v-if="!isQrCodesPrinting">Печать QrCode</span>
          <b-spinner small v-else />
        </button>
        <button
          class="btn btn-primary ml-2"
          @click="$bvModal.hide('qr-start-position-select-modal')"
        >
          Отменить
        </button>
      </div>
    </b-modal>

    <b-modal
      size="xl"
      scrollable
      v-model="isSelectedDevicesInfoVisible"
      hide-footer
    >
      <template #modal-title>
        Информация о выбранной технике
      </template>
      <div class="d-block">
        <b-table-simple small responsive>
          <b-thead>
            <b-tr>
              <b-th>Компания</b-th>
              <b-th>Офис</b-th>
              <b-th>Серийный номер</b-th>
              <b-th>Название</b-th>
              <b-th>Стикер</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, idx) in selectedItems" :key="idx">
              <b-td>{{ getCompanyNameById(item.companyId) }}</b-td>
              <b-td>{{ getOfficeNameById(item.officeId) }}</b-td>
              <b-td>{{ item.serialNum }}</b-td>
              <b-td>{{ item.title }}</b-td>
              <b-td>{{ item.stickerNum }}</b-td>
              <b-td title="Удалить запись">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="red"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  @click="removeSelectedItem(item.id)"
                >
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"
                  />
                  <path
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"
                  />
                </svg>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-end">
        <button
          class="btn btn-primary"
          @click="isSelectedDevicesInfoVisible = false"
        >
          Закрыть
        </button>
      </div>
    </b-modal>

    <!-- <ColumnsVisibilityChooser
      v-if="isColumnsVisibilityChooserModalVisible"
      :src="sortedSrc"
      @change-visibility="setColumnsVisibilityValue"
      @close="hideColumnsVisibilityChooserModal"
      @up="doMoveColumnUp"
      @down="doMoveColumnDown"
      /> -->

    <TableViewSettings
      v-if="isColumnsVisibilityChooserModalVisible"
      :src="Object.values(columnsVisibility)"
      @close="hideColumnsVisibilityChooserModal"
      @apply="doApplyTableViewSettings"
    />

    <AddViewerModal
      v-if="isAddViewerModalVisible"
      :employees="employees"
      @apply="applyViewerForSelectedDevices"
      @close="hideAddViewerModal"
    ></AddViewerModal>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
// import Checkbox from "../../components/Form/FormElement/Checkbox";
import RequestManager from "../../function/request/RequestManager";
import { mapGetters, mapState, mapActions } from "vuex";
import { GET_ALL_TAGS } from "../../store/types";
import vuescroll from "vuescroll";
import IconPlus from "../../components/Form/IconPlus";
import PlusButton from "../../components/PlusButton";
import FileSaver from "file-saver";
import HelpPopover from "../../components/HelpPopover";
// import ColumnsVisibilityChooser from "@/components/Modal/ColumnsVisibilityChooser.vue";
import TableViewSettings from "../../components/Modal/TableViewSettings.vue";
import AddViewerModal from "@/components/Technique/AddViewerModal.vue";

import { CompanyApi } from "../../function/request/apiV2";
import { OfficeApi } from "../../function/request/apiV2";
import { DeviceApi } from "../../function/request/apiV2";

let officesList = [];

const DEFAULT_COLUMNS_CONFIG = {
  img: { title: "Фото", value: true, order: 0, sortable: false, width: 0 },
  title: { title: "Название", value: true, order: 1, sortable: true, width: 0 },
  serialNum: {
    title: "Серийный номер",
    value: true,
    order: 2,
    sortable: true,
    width: 0
  },
  stickerNum: {
    title: "Стикер",
    value: true,
    order: 3,
    sortable: true,
    width: 0
  },
  viewer: {
    title: "Наблюдатель",
    value: true,
    order: 4,
    sortable: false,
    width: 0
  },
  costPerPage: {
    title: "Стоимость печати чб.",
    value: true,
    order: 5,
    sortable: true,
    width: 0
  },
  costPerPageColor: {
    title: "Стоимость печати цвет.",
    value: true,
    order: 6,
    sortable: true,
    width: 0
  },
  marks: {
    title: "Специальные метки",
    value: true,
    order: 7,
    sortable: false,
    width: 0
  }
};

let paramsForListDevices = {};

const STORAGE_PREFIX = "aura_tech_";

export default {
  name: "MyTechnique",
  components: {
    PlusButton,
    IconPlus,
    // Checkbox,
    TepmplateBlock,
    vuescroll,
    HelpPopover,
    // ColumnsVisibilityChooser,
    TableViewSettings,
    AddViewerModal
  },

  data() {
    return {
      helpPopovers: {
        PopoverOfIconPlus: "Кнопка создания новой задачи",
        PopoverOfTitlePage:
          "Раздел техника, здесь можно найти все аппараты и отфильтровать нужные",
        PopoverOfSearch:
          "Поиск аппарата происходит по включению введенного в его модель и/или номер",
        PopoverOfOffice: "Выборка аппаратов по привязке к офису",
        PopoverOfTypeDevice:
          "Выборка устройств по их типу (например, принтер, мфу, сканер)",
        PopoverOfChooseButton:
          "Данный пункт служит для выбора нескольких аппаратов",
        PopoverOfChooseButtonTags: "Сгруппировать аппараты по тэгам",
        PopoverOfChooseAllButton:
          "Выбрать все показанные на странице устройства",
        PopoverOfDeselectAllButton:
          "Снять выделение со всех показанных на странице устройств",
        PopoverOfPlusButton: "Кнопка создания новой задачи",
        PopoverOfPrintedPages:
          "Сортировка и фильтрация по количеству напечатанных страниц"
      },
      tagObject: {},
      searchString: "",
      checkedTypes: [],
      checkedOffices: null,
      checkedCompany: undefined,

      countersSumMin: "",
      countersSumMax: "",
      costPerPageMin: "",
      costPerPageMax: "",
      expensesMin: "",
      expensesMax: "",

      isFilterCountersSum: false,
      isFilterCostPerPage: false,
      isFilterExpenses: false,

      offices: [],
      types: {},
      active: false,
      sortByTags: false,
      alltags: [],
      items: [],
      itemsCount: 0,
      showArrTags: [],
      isShowSpinner: false,
      isShowQrSpinner: false,
      isCheckedMode: false,
      selectedItems: [],
      allCompaniesByParams: [],
      companyApi: new CompanyApi(),
      officeApi: new OfficeApi(),
      deviceApi: new DeviceApi(),
      checkedDevices: undefined,
      checkedRootOffices: null,
      allCompanies: [],
      allOffices: [],
      allDevices: [],
      offset: 0,
      limit: 10000,
      count: 30,
      currentUserCompany: 0,
      currentUserId: 0,
      mainCompanyInfo: undefined,
      isQrCodesPrinting: false,
      currentQrStartPosition: 0,
      isSelectedDevicesInfoVisible: false,
      isShowFilterInfo: false,

      listView: undefined,
      columnsVisibility: Object.assign({}, DEFAULT_COLUMNS_CONFIG),
      columnsVisibilityKey: 0,
      isColumnsVisibilityChooserModalVisible: false,
      sortType: "id",
      sortOrder: "DESC",
      isAddViewerModalVisible: false,
      employees: [],
      isEmployeesListLoading: false,
      isNeoPharm: false,
      currentQrURL: "",
      currentQrPhone: "",
      currentQrName: ""
    };
  },

  computed: {
    sortedColumnsArray() {
      return Object.keys(this.columnsVisibility);
    },

    sortedSrc() {
      return Object.values(this.columnsVisibility).sort(
        (a, b) => a.order - b.order
      );
    },

    concatCompanies() {
      let companies = [];
      companies = [...this.allCompaniesByParams, ...this.allCompanies];
      return [...companies];
    },

    canPrintQr() {
      // return ["1", "2"].includes(this.currentUser?.role);
      return true;
    },

    ...mapGetters(["officeList", "tagsList", "currentUser"]),
    ...mapState("devices", ["devicesTypes", "devicesStatus"]),

    query() {
      const queryObj = {};
      if (this.checkedTypes.length > 0) {
        let type1 = this.checkedTypes.map(item => {
          if (item == 1) return "printer";
          else if (item == 2) return "scanner";
          else if (item == 3) return "copier";
          else if (item == 4) return "mfp";
        });
        queryObj.type__in = type1.join(",");
      }
      if (this.checkedOffices) {
        // queryObj.office = this.checkedOffices.join(",");
        queryObj.office = this.checkedOffices;
      }
      queryObj.search = this.searchString ? this.searchString : undefined;
      return queryObj;
    },
    checkedTypesForField() {
      let allTypes = this.devicesTypes;
      let checked = this.checkedTypes;
      let formatted = checked.map(el => {
        return allTypes[el];
      });
      return formatted && formatted.length
        ? formatted.join(", ")
        : "Не выбрано";
    }
  },

  props: {
    statusColor: {
      goods: Boolean,
      load: Boolean,
      inWork: Boolean
    }
  },

  watch: {
    items(items) {
      const tagObject = {};
      this.tagsList.results.forEach(tag => {
        const tagArray = [];
        items.forEach(item => {
          const exists = item.tags.some(itemtag => itemtag.title === tag.title);
          if (exists) {
            tagArray.push(item);
          }
        });
        if (tagArray.length > 0) {
          tagObject[tag.title] = tagArray;
        }
      });
      this.tagObject = tagObject;
      this.tagObject = Object.fromEntries(
        Object.entries(this.tagObject).sort()
      );
    }
  },

  methods: {
    doMoveColumnUp(column) {
      /* const foundIdx = this.sortedColumnsArray.findIndex(col => col === column);
      if (foundIdx > 0) {
        this.columnsVisibility[column].order -= 1;
        this.columnsVisibility[
          this.sortedColumnsArray[foundIdx - 1]
        ].order += 1;
        this.columnsVisibility = Object.assign({}, this.columnsVisibility);
      } */
      const itemValues = Object.values(this.columnsVisibility);
      const foundItemIdx = itemValues.findIndex(
        item => item.title === column.title
      );
      if (foundItemIdx > -1) {
        itemValues[foundItemIdx].order -= 1;
        itemValues[foundItemIdx - 1].order += 1;
      }
    },

    doMoveColumnDown(column) {
      /* const foundIdx = this.sortedColumnsArray.findIndex(col => col === column);
      if (foundIdx < this.sortedColumnsArray.length - 1) {
        this.columnsVisibility[column].order += 1;
        this.columnsVisibility[
          this.sortedColumnsArray[foundIdx + 1]
        ].order -= 1;
        this.columnsVisibility = Object.assign({}, this.columnsVisibility);
      } */
      const itemValues = Object.values(this.columnsVisibility);
      const foundItemIdx = itemValues.findIndex(
        item => item.title === column.title
      );
      if (foundItemIdx > -1) {
        itemValues[foundItemIdx].order += 1;
        itemValues[foundItemIdx + 1].order -= 1;
      }
    },

    doApplyTableViewSettings(settings) {
      const newColumnsVisibility = {};
      let idx = 0;
      settings.forEach(setting => {
        Object.keys(this.columnsVisibility).forEach(key => {
          if (this.columnsVisibility[key].title === setting.title) {
            setting.order = idx;
            newColumnsVisibility[key] = setting;
            idx++;
          }
        });
      });
      this.columnsVisibility = Object.assign(newColumnsVisibility);
      this.isColumnsVisibilityChooserModalVisible = false;
      localStorage.setItem(
        `${STORAGE_PREFIX}cols_visibility`,
        JSON.stringify(this.columnsVisibility)
      );
    },

    showAddViewerModal() {
      this.isAddViewerModalVisible = true;
    },

    hideAddViewerModal() {
      this.isAddViewerModalVisible = false;
    },

    applyViewerForSelectedDevices(selectedUserId) {
      this.selectedItems.forEach(item => {
        item.viewers = [{ id: selectedUserId }];
        RequestManager()
          .deviceApi.editDevice(item.id, { body: item })
          .then(response => {
            let foundItem = this.items.find(item => item.id === response.id);
            if (foundItem) {
              this.$set(foundItem, "viewers", response.viewers);
            }
          });
      });
      this.hideAddViewerModal();
    },

    toggleIsShowFilterInfo() {
      this.isShowFilterInfo = !this.isShowFilterInfo;
      localStorage.setItem("is-show-filter-info", this.isShowFilterInfo);
    },

    toggleListView(viewType) {
      this.listView = viewType;
      localStorage.setItem("techniqueListView", viewType);
    },

    toggleSelectAll() {
      if (this.selectedItems.length === this.items.length) {
        this.selectedItems = [];
        this.isCheckedMode = false;
      } else {
        this.selectedItems = this.items;
        this.isCheckedMode = true;
      }
    },

    hideColumnsVisibilityChooserModal() {
      this.isColumnsVisibilityChooserModalVisible = false;
    },

    setSorting(sorting) {
      const foundColumnConfig = this.columnsVisibility[sorting];
      if (foundColumnConfig && foundColumnConfig.sortable) {
        if (this.sortType === sorting) {
          this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        }
        this.sortType = sorting;
        this.fetchData();
      }
    },

    getImageDeviceOrTechnique(item) {
      if (item?.picturePath?.length > 0) {
        return this.picture(item.picturePath);
      } else {
        if (item?.files) {
          return item.files[item.files.length - 1].url;
        } else {
          return "https://placehold.it/90x80";
        }
      }
    },

    picture(path) {
      return process.env.VUE_APP_URL + "/media/" + path;
    },

    pictureServerorEmpty(url) {
      if (url) {
        return url;
      }
      return "https://placehold.it/90x80";
    },

    async getRootCompaniesByParams() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: this.limit,
        ids: [this.currentUserCompany]
      });
      this.allCompaniesByParams = items;
    },

    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: this.limit,
        crmManagerId: this.currentUserId
      });
      this.allCompanies = items;
    },

    async getRootOffices() {
      let allCompaniesIds = this.allCompanies.map(comp => parseInt(comp.id));
      allCompaniesIds.unshift(parseInt(this.mainCompanyInfo.id));
      const { items } = await this.officeApi.api.listOffice({
        limit: 5000,
        companyIds: [allCompaniesIds]
      });
      this.allOffices = items;
      officesList = items;
      //await this.getRootDevices();
      this.checkedOffices = null;
      this.getRootDevices();
    },

    async getRootDevices() {
      this.allDevices = [];

      this.setParamsForListDevices();
      const { items } = await this.deviceApi.api.listDevices(
        paramsForListDevices
      );

      // const { items } = await this.deviceApi.api.listDevices({
      //   companyId: this.checkedCompany,
      //   officeIds: [this.checkedOffices],
      //   limit: this.limit,
      //   sort: "id",
      //   order: "DESC"
      // });

      //  console.log("items",items);

      this.allDevices = items.map(el => {
        return { ...el, link: "/my-techique/" + el.id };
      });
    },

    async getRootDevicesWithParams(params) {
      this.allDevices = [];

      const { items } = await this.deviceApi.api.listDevices(params);

      //  console.log("items",items);

      this.allDevices = items.map(el => {
        return { ...el, link: "/my-techique/" + el.id };
      });
    },

    applyFilters() {
      this.saveFilterParams();
      let eventParam = { key: "Enter" };
      this.onKeyDown(eventParam);
    },

    saveFilterParams() {
      let params = {};

      params["name"] = this.searchString;
      params["companyId"] = this.checkedCompany;
      params["officeIds"] = this.checkedOffices;
      if (this.countersSumMin) {
        params.countersSum = ["wear", "color"];
        params.countersSumMin = parseInt(this.countersSumMin);
      }
      if (this.countersSumMax) {
        params.countersSum = ["wear", "color"];
        params.countersSumMax = parseInt(this.countersSumMax);
      }
      if (this.costPerPageMin) {
        params.costPerPageMin = parseInt(this.costPerPageMin);
      }
      if (this.costPerPageMax) {
        params.costPerPageMax = parseInt(this.costPerPageMax);
      }
      if (this.expensesMin) {
        params.expensesMin = parseInt(this.expensesMin);
      }
      if (this.expensesMax) {
        params.expensesMax = parseInt(this.expensesMax);
      }
      params.isShowFilterInfo = this.isShowFilterInfo;
      localStorage.setItem("savedFilterParams", JSON.stringify(params));
    },

    clearFilter() {
      localStorage.setItem("savedFilterParams", "");
      this.searchString = "";
      this.countersSumMin = "";
      this.countersSumMax = "";
      this.costPerPageMin = "";
      this.costPerPageMax = "";
      this.expensesMin = "";
      this.expensesMax = "";
      delete paramsForListDevices["name"];
      delete paramsForListDevices["companyId"];
      delete paramsForListDevices["checkedOffices"];
      delete paramsForListDevices["countersSumMin"];
      delete paramsForListDevices["countersSumMax"];
      delete paramsForListDevices["costPerPageMin"];
      delete paramsForListDevices["costPerPageMax"];
      delete paramsForListDevices["expensesMin"];
      delete paramsForListDevices["expensesMax"];
      delete paramsForListDevices["sort"];
      delete paramsForListDevices["order"];

      this.checkedCompany = this.concatCompanies[0].id;
      this.checkedOffices = null;

      let eventParam = { key: "Enter" };
      this.onKeyDown(eventParam);
    },

    searchRootOffices() {
      this.getRootOffices();
    },

    async searchByCompany() {
      this.isShowSpinner = true;

      this.saveFilterParams();

      this.allOffices = officesList.filter(
        office => office.companyId === parseInt(this.checkedCompany)
      );
      this.checkedOffices = null;

      this.setParamsForListDevices();
      const { items } = await this.deviceApi.api.listDevices(
        paramsForListDevices
      );

      this.items = items.map(item => {
        return { ...item, link: "/my-techique/" + item.id };
      });

      this.isShowSpinner = false;
    },

    async searchByOffice() {
      this.isShowSpinner = true;

      this.saveFilterParams();

      this.setParamsForListDevices();
      const { items } = await this.deviceApi.api.listDevices(
        paramsForListDevices
      );

      // let params = {
      //   companyId: this.checkedCompany,
      //   limit: this.limit,
      //   sort: "id",
      //   order: "DESC",
      //   type: this.query.type__in,
      //   name: this.query.search,
      //   officeIds: [this.checkedOffices]
      // };

      // if(this.countersSumMin){
      //   params.countersSum = ["wear", "color"];
      //   params.countersSumMin = parseInt(this.countersSumMin);
      // };
      // if(this.countersSumMax){
      //   params.countersSum = ["wear", "color"];
      //   params.countersSumMax = parseInt(this.countersSumMax);
      // };
      // if(this.costPerPageMin){
      //   params.costPerPageMin = parseInt(this.costPerPageMin);
      // };
      // if(this.costPerPageMax){
      //   params.costPerPageMax = parseInt(this.costPerPageMax);
      // };
      // if(this.expensesMin){
      //   params.expensesMin = parseInt(this.expensesMin);
      // };
      // if(this.expensesMax){
      //   params.expensesMax = parseInt(this.expensesMax);
      // };

      // const { items } = await this.deviceApi.api.listDevices(params);

      this.items = items.map(item => {
        return { ...item, link: "/my-techique/" + item.id };
      });

      this.isShowSpinner = false;
    },

    async getQrListByOffice() {
      try {
        this.isShowQrSpinner = true;
        const _params = {
          office_id: this.checkedOffices ?? undefined
          // device_id: this.selectedItems?.length
          //   ? this.selectedItems?.map(el => el.id).join(",")
          //   : undefined
        };
        const response = await RequestManager().getQrListForDevicesByOfficeId(
          _params
        );
        if (response?.success === false) {
          this.$bvToast.toast("Произошла непредвиденная ошибка", {
            variant: "danger",
            title: "Ошибка"
          });
        } else {
          const file = new Blob([response], { type: "application/pdf" });
          FileSaver.saveAs(
            file,
            `${
              this.selectedOffice
                ? `office_${this.selectedOffice}_qr_list.pdf`
                : "qr-list.pdf"
            }`
          );
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.isShowQrSpinner = false;
      }
    },

    createTechnique() {
      if (!this.isCheckedMode) {
        this.$router.push({ name: "NewItems" });
      } else {
        this.$refs.refCreateOrder.showModal();
      }
    },

    isItemChecked(device) {
      return !!this.selectedItems?.find(el => el.id === device.id);
    },

    selectItem(device) {
      const isHasDeviceInArray = !!this.selectedItems?.find(
        el => el.id === device.id
      );
      if (isHasDeviceInArray) {
        this.selectedItems = this.selectedItems?.filter(
          el => el.id !== device.id
        );
        if (!this.selectedItems.length) {
          this.isCheckedMode = false;
        }
      } else {
        this.isCheckedMode = true;
        this.selectedItems = [...this.selectedItems, device];
      }
    },

    removeSelectedItem(itemId) {
      const foundItemIdx = this.selectedItems.findIndex(
        item => item.id === itemId
      );
      if (foundItemIdx > -1) {
        this.selectedItems.splice(foundItemIdx, 1);
      }
    },

    toggleCheckedMode() {
      this.isCheckedMode = !this.isCheckedMode;
      if (!this.isCheckedMode) {
        this.selectedItems = [];
      }
    },

    changeModeFilterCountersSum() {
      this.isFilterCountersSum = !this.isFilterCountersSum;
      this.isFilterCostPerPage = false;
      this.isFilterExpenses = false;
    },

    changeModeFilterCostPerPage() {
      this.isFilterCountersSum = false;
      this.isFilterCostPerPage = !this.isFilterCostPerPage;
      this.isFilterExpenses = false;
    },

    changeModeFilterExpenses() {
      this.isFilterCountersSum = false;
      this.isFilterCostPerPage = false;
      this.isFilterExpenses = !this.isFilterExpenses;
    },

    selectAllVisibleItems() {
      this.isCheckedMode = true;
      this.items.forEach(device => {
        const foundSelectedItem = this.selectedItems.find(
          item => item.id === device.id
        );
        if (!foundSelectedItem) {
          this.selectItem(device);
        }
      });
    },

    deselectAllVisibleItems() {
      this.items.forEach(device => {
        const foundSelectedItemIdx = this.selectedItems.findIndex(
          item => item.id === device.id
        );
        if (foundSelectedItemIdx > -1) {
          this.$set(this.selectedItems, [
            ...this.selectedItems.splice(foundSelectedItemIdx, 1)
          ]);
        }
      });
    },

    orderCreated(order) {
      this.$router.push({ name: "RequestChat", params: { id: order.id } });
    },

    async searchDevices() {
      this.isShowSpinner = true;

      this.setParamsForListDevices();
      const { items } = await this.deviceApi.api.listDevices(
        paramsForListDevices
      );

      // const { items } = await this.deviceApi.api.listDevices({
      //   companyId: this.checkedCompany,
      //   limit: this.limit,
      //   sort: "id",
      //   order: "DESC",
      //   type: this.query.type__in,
      //   name: this.query.search,
      //   officeIds: [this.checkedOffices]
      // });

      this.items = items.map(item => {
        return { ...item, link: "/my-techique/" + item.id };
      });

      this.isShowSpinner = false;

      /*  this.isShowSpinner = true;
      this.items = [];
      this.selectedItems = [];
     RequestManager()
        .getAllDevices({
          ...this.query
        })
        .then(data => {
          this.items = data.results.map(item => {
            return { ...item, link: "/my-techique/" + item.id };
          });
          this.itemsCount = data.count;
        })
        .finally(() => {
          this.isShowSpinner = false;
        })  */
    },

    openOrClose(name) {
      return this.showArrTags.includes(name) ? "office-close" : "office-open";
    },

    showItems(key) {
      if (!this.showArrTags.includes(key)) {
        this.showArrTags.push(key);
      } else {
        this.showArrTags = this.showArrTags.filter(el => el !== key);
      }
    },

    checkShow(key) {
      return this.showArrTags.includes(key);
    },

    handleScroll(vertical) {
      const sentQuery = Object.keys(this.query).length > 0 ? this.query : null;
      if (vertical.process === 1 && this.items.length < this.itemsCount) {
        RequestManager()
          .getAllDevices(sentQuery, this.items.length)
          .then(data => {
            const items = data.results.map(item => {
              return { ...item, link: "/my-techique/" + item.id };
            });
            this.items = [...this.items, ...items];
            this.itemsCount = data.count;
          });
      }
    },

    onSortClick() {
      this.$set(this, "sortByTags", !this.sortByTags);
      this.selectedItems = [];
      this.isCheckedMode = false;
    },

    async onKeyDown(event) {
      if (event.key === "Enter") {
        this.saveFilterParams();

        this.isShowSpinner = true;

        this.setParamsForListDevices();
        const { items } = await this.deviceApi.api.listDevices(
          paramsForListDevices
        );

        this.items = items.map(item => {
          return { ...item, link: "/my-techique/" + item.id };
        });

        this.isShowSpinner = false;

        /*  this.isShowSpinner = true;
        this.intems = [];
        RequestManager()
          .getAllDevices({
            ...this.query
          })
          .then(data => {
            this.items = data.results.map(item => {
              return { ...item, link: "/my-techique/" + item.id };
            });
            this.itemsCount = data.count;
          })
          .finally(() => (this.isShowSpinner = false)); */
      }
    },

    async onTypeChecked(data) {
      if (data.checked) {
        //  this.checkedTypes = [...this.checkedTypes, data.value];
        this.checkedTypes = [data.value];
      } else {
        this.checkedTypes = this.checkedTypes.filter(
          elem => elem !== data.value
        );
      }

      this.isShowSpinner = true;

      this.setParamsForListDevices();
      const { items } = await this.deviceApi.api.listDevices(
        paramsForListDevices
      );
      // const { items } = await this.deviceApi.api.listDevices({
      //   companyId: this.checkedCompany,
      //   limit: this.limit,
      //   sort: "id",
      //   order: "DESC",
      //   type: this.query.type__in,
      //   name: this.query.search,
      //   officeIds: [this.checkedOffices]
      // });

      this.items = items.map(item => {
        return { ...item, link: "/my-techique/" + item.id };
      });

      this.isShowSpinner = false;
    },

    // async onOfficeChecked(data) {
    //   if (data.checked) {
    //     this.checkedOffices = [...this.checkedOffices, data.value];
    //   } else {
    //     this.checkedOffices = this.checkedOffices.filter(
    //       elem => elem !== data.value
    //     );
    //   }

    //   this.isShowSpinner = true;

    //   this.setParamsForListDevices();
    //   const { items } = await this.deviceApi.api.listDevices(paramsForListDevices)

    // const { items } = await this.deviceApi.api.listDevices({
    //   companyId: this.checkedCompany,
    //   limit: this.limit,
    //   sort: "id",
    //   order: "DESC",
    //   type: this.query.type__in,
    //   name: this.query.search,
    //   officeIds: [this.checkedOffices]
    // });

    //   this.items = items.map(item => {
    //     return { ...item, link: "/my-techique/" + item.id };
    //   });

    //   this.isShowSpinner = false;

    // },

    async fetchData() {
      this.isShowSpinner = true;

      paramsForListDevices["companyId"] = this.checkedCompany;
      paramsForListDevices["limit"] = this.limit;
      paramsForListDevices["sort"] = this.sortType || "id";
      paramsForListDevices["order"] = this.sortOrder || "DESC";

      const { items } = await this.deviceApi.api.listDevices(
        paramsForListDevices
      );
      this.isNeoPharm = items[0].companyId === "425";
      const currentQrURLSrc = localStorage.getItem("currentQrURL");
      if (currentQrURLSrc) {
        this.currentQrURL = currentQrURLSrc;
      } else {
        this.currentQrURL = this.isNeoPharm
          ? `${location.host}/qrcodeorder-neopharm`
          : `${location.host}/qrcodeorder`;
      }
      const currentQrPhoneSrc = localStorage.getItem("currentQrPhone");
      if (currentQrPhoneSrc) {
        this.currentQrPhone = currentQrPhoneSrc;
      } else {
        this.currentQrPhone = "";
      }
      const currentQrNameSrc = localStorage.getItem("currentQrName");
      if (currentQrNameSrc) {
        this.currentQrName = currentQrNameSrc;
      } else {
        this.currentQrName = "";
      }

      this.items = items.map(item => {
        return { ...item, link: "/my-techique/" + item.id };
      });

      this.isShowSpinner = false;
    },

    printQrForSomeTechnique() {
      this.currentQrStartPosition = 0;
      this.$bvModal.show("qr-start-position-select-modal");
    },

    async doApplyPrintQr() {
      this.isQrCodesPrinting = true;
      localStorage.setItem("currentQrURL", this.currentQrURL);
      localStorage.setItem("currentQrName", this.currentQrName);
      localStorage.setItem("currentQrPhone", this.currentQrPhone);
      const { path, url } = await RequestManager().qrApi.devicesListQrs({
        ids: this.selectedItems.map(item => item.id),
        url: this.currentQrURL,
        pageOffset: this.currentQrStartPosition,
        phone: this.currentQrPhone,
        title: this.currentQrName,
        format: "pdf"
      });
      console.log(path);
      this.isQrCodesPrinting = false;
      this.$bvModal.hide("qr-start-position-select-modal");
      window.open(url, "_blank");
    },

    getCompanyNameById(id) {
      const foundCompany = this.concatCompanies.find(
        company => company.id === id
      );
      if (foundCompany) {
        return foundCompany.brand;
      } else {
        return "-";
      }
    },

    getOfficeNameById(id) {
      const foundOffice = officesList.find(
        office => office.id === parseInt(id)
      );
      if (foundOffice) {
        return foundOffice.name;
      } else {
        return "-";
      }
    },

    readAllParamsForListDevices() {
      let paramsTemp = localStorage.getItem("savedFilterParams");
      if (paramsTemp) {
        paramsTemp = JSON.parse(paramsTemp);

        this.isShowFilterInfo = paramsTemp.isShowFilterInfo;

        if (paramsTemp["name"] && paramsTemp["name"].length > 0) {
          this.searchString = paramsTemp["name"];
          paramsForListDevices["name"] = paramsTemp["name"];
        }
        if (paramsTemp["companyId"] && paramsTemp["companyId"].length > 0) {
          this.checkedCompany = paramsTemp["companyId"];
          paramsForListDevices["companyId"] = paramsTemp["companyId"];
        }
        if (paramsTemp["officeIds"] && paramsTemp["officeIds"] > 0) {
          this.checkedOffices = paramsTemp["officeIds"];
          paramsForListDevices["officeIds"] = [paramsTemp["officeIds"]];
        } else {
          this.checkedOffices = null;
        }

        if (paramsTemp?.countersSumMin) {
          paramsForListDevices.countersSumMin = paramsTemp.countersSumMin;
          paramsForListDevices.countersSum = ["wear", "color"];
          this.countersSumMin = paramsTemp.countersSumMin;
          this.isShowFilterInfo = true;
        }
        if (paramsTemp?.countersSumMax) {
          paramsForListDevices.countersSumMax = paramsTemp.countersSumMax;
          paramsForListDevices.countersSum = ["wear", "color"];
          this.countersSumMax = paramsTemp.countersSumMax;
          this.isShowFilterInfo = true;
        }
        if (paramsTemp?.costPerPageMin) {
          paramsForListDevices.costPerPageMin = paramsTemp.costPerPageMin;
          this.costPerPageMin = paramsTemp.costPerPageMin;
          this.isShowFilterInfo = true;
        }
        if (paramsTemp?.costPerPageMax) {
          paramsForListDevices.costPerPageMax = paramsTemp.costPerPageMax;
          this.costPerPageMax = paramsTemp.costPerPageMax;
          this.isShowFilterInfo = true;
        }
        if (paramsTemp?.expensesMin) {
          paramsForListDevices.expensesMin = paramsTemp.expensesMin;
          this.expensesMin = paramsTemp.expensesMin;
          this.isShowFilterInfo = true;
        }
        if (paramsTemp?.expensesMax) {
          paramsForListDevices.expensesMax = paramsTemp.expensesMax;
          this.expensesMax = paramsTemp.expensesMax;
          this.isShowFilterInfo = true;
        }
      }
    },

    setParamsForListDevices() {
      delete paramsForListDevices["officeIds"];
      delete paramsForListDevices["name"];
      delete paramsForListDevices["companyId"];
      delete paramsForListDevices["countersSum"];
      delete paramsForListDevices["countersSumMin"];
      delete paramsForListDevices["countersSumMax"];
      delete paramsForListDevices["costPerPageMin"];
      delete paramsForListDevices["costPerPageMax"];
      delete paramsForListDevices["expensesMin"];
      delete paramsForListDevices["expensesMax"];
      delete paramsForListDevices["sort"];
      delete paramsForListDevices["order"];

      paramsForListDevices["companyId"] = this.checkedCompany;

      paramsForListDevices["officeIds"] = [this.checkedOffices];
      if (this.query?.search?.length > 0) {
        paramsForListDevices["name"] = this.query.search;
      }

      if (this.countersSumMin) {
        paramsForListDevices.countersSum = ["wear", "color"];
        paramsForListDevices.countersSumMin = parseInt(this.countersSumMin);
      }
      if (this.countersSumMax) {
        paramsForListDevices.countersSum = ["wear", "color"];
        paramsForListDevices.countersSumMax = parseInt(this.countersSumMax);
      }
      if (this.costPerPageMin) {
        paramsForListDevices.costPerPageMin = parseFloat(this.costPerPageMin);
      }
      if (this.costPerPageMax) {
        paramsForListDevices.costPerPageMax = parseFloat(this.costPerPageMax);
      }
      if (this.expensesMin) {
        paramsForListDevices.expensesMin = parseInt(this.expensesMin);
      }
      if (this.expensesMax) {
        paramsForListDevices.expensesMax = parseInt(this.expensesMax);
      }

      paramsForListDevices["sort"] = "id";
      paramsForListDevices["order"] = "DESC";

      if (this.isFilterCountersSum) {
        paramsForListDevices.countersSum = ["wear", "color"];
        paramsForListDevices["sort"] = "countersSum";
        paramsForListDevices["order"] = "DESC";
      }
      if (this.isFilterCostPerPage) {
        paramsForListDevices["sort"] = "costPerPage";
        paramsForListDevices["order"] = "DESC";
      }
      if (this.isFilterExpenses) {
        paramsForListDevices["sort"] = "expenses";
        paramsForListDevices["order"] = "DESC";
      }
    },

    setColumnsVisibilityValue(newColumnsVisibility) {
      this.columnsVisibility = Object.assign({}, newColumnsVisibility);
      localStorage.setItem(
        `${STORAGE_PREFIX}cols_visibility`,
        JSON.stringify(this.columnsVisibility)
      );
      this.columnsVisibilityKey += 1;
    },

    getSavedColumnsVisibilityOrDefault() {
      const columnsVisibilitySrc = localStorage.getItem(
        `${STORAGE_PREFIX}cols_visibility`
      );
      this.columnsVisibility = columnsVisibilitySrc
        ? Object.assign({}, JSON.parse(columnsVisibilitySrc))
        : Object.assign({}, DEFAULT_COLUMNS_CONFIG);
    },

    ...mapActions({ GET_ALL_TAGS })
  },

  async mounted() {
    this.GET_ALL_TAGS();
    this.mainCompanyInfo = JSON.parse(
      window.localStorage.getItem("mainCompanyInfo")
    );
    if ("id" in this.currentUser) {
      this.currentUserCompany = this.currentUser.company;
      this.currentUserId = this.currentUser.id;
      localStorage.setItem("currentUserCompany", this.currentUserCompany);
      localStorage.setItem("currentUserId", this.currentUserId);
    } else {
      this.currentUserCompany = +localStorage.getItem("currentUserCompany");
      this.currentUserId = +localStorage.getItem("currentUserId");
    }

    this.isEmployeesListLoading = true;
    const { results } = await RequestManager().getUsersList();
    this.employees = results;
    this.isEmployeesListLoading = false;

    //  if (this.currentUser.role === "2" || this.currentUser.role === "3") {
    this.isShowSpinner = true;
    if (
      this.companyApi.api &&
      this.companyApi.api.listCompanies &&
      this.officeApi &&
      this.officeApi.api.listOffice &&
      this.deviceApi.api &&
      this.deviceApi.api.listDevices
    ) {
      try {
        await this.getRootCompanies();
        if (this.currentUserCompany) {
          await this.getRootCompaniesByParams();

          //   }
        }
        //   if (this.currentUser.role !== "2" || this.currentUser.role !== "3") {
        // this.fetchData();
        //   }

        this.checkedCompany = this.concatCompanies[0].id;

        this.readAllParamsForListDevices();
        await this.getRootOffices();
        this.allOffices = officesList.filter(
          office => office.companyId === parseInt(this.checkedCompany)
        );
        this.readAllParamsForListDevices();
        this.fetchData();
      } finally {
        this.isShowSpinner = false;
      }
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    // console.log("this.currentUserId", this.currentUserId);
    // console.log("this.currentUserCompany", this.currentUserCompany);
    // console.log("concatCompanies", this.concatCompanies);
  },

  created() {
    this.getSavedColumnsVisibilityOrDefault();
    this.listView = localStorage.getItem("techniqueListView") || "grid";
    const isShowFilterInfoSrc = localStorage.getItem("is-show-filter-info");
    this.isShowFilterInfo =
      isShowFilterInfoSrc && isShowFilterInfoSrc?.length
        ? JSON.parse(localStorage.getItem("is-show-filter-info"))
        : true;
  }
};
</script>
<style lang="scss">
.popover {
  height: auto !important;
}

.admin-panel {
  margin: 10px 0;
}
</style>
<style lang="scss" scoped>
#grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 6px;
}
.scroll-container {
  width: 100% !important;
  margin: 0 auto;
  overflow: hidden;
}
.form-mytechnique {
  position: relative;
  padding: 3px;
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 18px;
}

.form-mytechnique__icon {
  &::before {
    content: "";
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
    height: 24px;
    background-image: url("../../resource/img/lupa.svg");
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
}

.form-mytechnique__search {
  position: relative;
  width: 230px;
  height: 50px;
  padding-left: 25px;

  border: 1px solid rgba(206, 206, 206, 0.2);
  background-color: rgba(206, 206, 206, 0.2);
  border-radius: 6px;

  //background-color: rgba(206, 206, 206, 0.2);

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
}
.form-mytechnique__search.analiticsParams {
  padding-left: 10px;
  width: 80px;

  display: flex;
}
.form-mytechnique__sort-wrap.sorted {
  button {
    background: #016b90;
  }
}
.form-mytechnique__sort-btn {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  width: 220px;
  height: 16px;
  outline: none;

  padding: 15px 16px 14px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #006b90;

  span {
    display: inline-block;
    margin-left: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 18px;
    height: 15px;
    background-image: url("../../resource/img/sort.svg");
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }

  &_active {
    background: #006b90;
    border-radius: 6px;
    color: #ffffff;

    &::before {
      background-image: url("../../resource/img/sort-hv.svg");
    }
  }
}
.form-mytechnique__sort-btn.sorted {
  color: #ffffff;
}
.form-mytechnique__sort-btn.deselect-all {
  width: 240px !important;
}

.form-mytechnique__checkbox-wrap {
  min-width: 100px;
  margin-bottom: 14px;
  display: flex;
  flex-wrap: wrap;
}

.form-mytechnique__filter-btn {
  position: absolute;
  top: 13px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 6px 15px;
  width: 30px;
  height: auto;
  border-radius: 50%;
  border: 0;

  &:hover,
  &:focus {
    background: rgba(8, 116, 162, 0.15);
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.form-mytechnique__popover {
  padding: 30px 28px 28px;
  width: 331px;
  min-height: 300px;
  background: #ffffff;
  color: var(--card-text-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.form-mytechnique__wrap {
  position: relative;
}

.form-mytechnique__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--main-text-color);
}

.form-mytechnique__checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
}

.mytechnique {
  margin: 0;
  width: 100%;
}

@media (min-width: 320px) {
  .mytechnique-wrap {
    width: 100% !important;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 115px;
    height: 100%;
  }
}
.mytechnique__item {
  min-height: 220px;
  width: 150px;
  padding: 28px 8px 11px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  //&:not(:last-child) {
  //  margin-right: 13px;
  //}
}

.mytechnique__title {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  word-break: break-word;
  color: var(--card-text-color);
}

.mytechnique__img {
  width: 90px;
  height: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
}

.mytechnique__model {
  margin: 0 0 17px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 0.04em;

  color: var(--main-text-color);
  font-weight: bold;
}

.mytechnique__status {
  position: relative;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;

  text-align: center;

  span::before {
    content: "";
    position: absolute;
    top: 2.5px;
    left: 9px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  &_orange {
    color: #fcbc04;

    span::before {
      background-color: #fcbc04;
    }
  }

  &_green {
    color: #4cac44;

    span::before {
      background-color: #4cac44;
    }
  }

  &_blue {
    color: #1c74fc;

    span::before {
      background-color: #1c74fc;
    }
  }
}

//  .mytechnique__status-text {
//  }

.mytechnique__link {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.plus-btn {
  position: absolute;
  bottom: 45px;
  right: 36px;

  display: block;

  width: 80px;
  height: 80px;

  background: #006b90;
  border-radius: 50%;

  font-size: 0;

  border: 0;

  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;

    width: 50px;
    height: 50px;

    background-image: url("../../resource/img/plus-big.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover,
  &:focus {
    background: lighten(#006b90, 10%);
  }
}

.item-selected {
  border: 2px #016b90 solid;
}

.tagname {
  position: relative;
  background: #e1ecf1;
  color: #016b90;
  padding: 10px 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  cursor: pointer;

  &__check {
    transition: all 0.2s ease;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-right: 1px #016b90 solid;
    border-bottom: 1px #016b90 solid;
    margin-right: 8px;
  }

  .office-open {
    transform: rotate(45deg);
    margin-bottom: 2px;
  }

  .office-close {
    transform: rotate(225deg);
    margin-bottom: -3px;
  }
}

.help-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.form-control {
  background-color: var(--form-for-my-technique-search);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.custom-select {
  background-color: var(--form-for-my-technique-search);
  color: #495057;
  &:focus {
    background-color: var(--main-card-color);
  }
}

::v-deep .highlight {
  background-color: var(--highlight-color);
}

.toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #2c698c;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px #2c698c solid;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s ease;
    position: relative;

    .up,
    .down {
      position: absolute;
      display: block;
      border-left: 2px #2c698c solid;
      border-top: 2px #2c698c solid;
      width: 15px;
      height: 15px;
    }

    .up {
      transform: rotate(45deg);
      top: 12px;
    }

    .down {
      transform: rotate(-135deg);
      top: 6px;
    }
  }
  .min:before {
    content: "<";
  }
  .max:before {
    content: ">";
  }
}
.clear {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-right: 30px;
  align-items: end;
}

table.no-border {
  border: none;
}

#grid-view {
  width: 16px;
  height: 16px;
  margin: 5px;
  cursor: pointer;
  opacity: 0.5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.91 1.455c-.804 0-1.456.65-1.456 1.454v1.455c0 .804.652 1.455 1.455 1.455h1.455c.803 0 1.454-.651 1.454-1.455V2.91c0-.803-.65-1.454-1.454-1.454H2.909ZM0 2.909A2.91 2.91 0 0 1 2.91 0h1.454a2.91 2.91 0 0 1 2.909 2.91v1.454a2.91 2.91 0 0 1-2.91 2.91H2.91A2.91 2.91 0 0 1 0 4.364V2.91Zm11.636-1.454c-.803 0-1.454.65-1.454 1.454v1.455c0 .804.65 1.455 1.454 1.455h1.455c.803 0 1.455-.651 1.455-1.455V2.91c0-.803-.652-1.454-1.455-1.454h-1.455ZM8.727 2.909A2.91 2.91 0 0 1 11.637 0h1.454A2.91 2.91 0 0 1 16 2.91v1.454a2.91 2.91 0 0 1-2.91 2.91h-1.454a2.91 2.91 0 0 1-2.909-2.91V2.91ZM2.91 10.181c-.803 0-1.455.651-1.455 1.455v1.455c0 .803.652 1.455 1.455 1.455h1.455c.803 0 1.454-.652 1.454-1.455v-1.455c0-.804-.65-1.455-1.454-1.455H2.909ZM0 11.636a2.91 2.91 0 0 1 2.91-2.91h1.454a2.91 2.91 0 0 1 2.909 2.91v1.455A2.91 2.91 0 0 1 4.363 16H2.91A2.91 2.91 0 0 1 0 13.09v-1.454Zm11.636-1.455c-.803 0-1.454.651-1.454 1.455v1.455c0 .803.65 1.455 1.454 1.455h1.455c.803 0 1.455-.652 1.455-1.455v-1.455c0-.804-.652-1.455-1.455-1.455h-1.455Zm-2.909 1.455a2.91 2.91 0 0 1 2.91-2.91h1.454A2.91 2.91 0 0 1 16 11.636v1.455A2.91 2.91 0 0 1 13.09 16h-1.454a2.91 2.91 0 0 1-2.909-2.91v-1.454Z' fill='%23333'/%3E%3C/svg%3E");
}
#table-view {
  width: 16px;
  height: 16px;
  margin: 5px;
  cursor: pointer;
  opacity: 0.5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.91 1.455c-.804 0-1.456.65-1.456 1.454v1.455c0 .804.652 1.455 1.455 1.455H13c.803 0 1.455-.651 1.455-1.455V2.91c0-.803-.652-1.454-1.455-1.454H2.91ZM0 2.909A2.91 2.91 0 0 1 2.91 0H13a2.91 2.91 0 0 1 2.91 2.91v1.454A2.91 2.91 0 0 1 13 7.274H2.91A2.91 2.91 0 0 1 0 4.364V2.91Zm2.91 7.272c-.804 0-1.456.651-1.456 1.455v1.455c0 .803.652 1.455 1.455 1.455H13c.803 0 1.455-.652 1.455-1.455v-1.455c0-.804-.652-1.455-1.455-1.455H2.91ZM0 11.636a2.91 2.91 0 0 1 2.91-2.91H13a2.91 2.91 0 0 1 2.91 2.91v1.455A2.91 2.91 0 0 1 13 16H2.91A2.91 2.91 0 0 1 0 13.09v-1.454Z' fill='%23333'/%3E%3C/svg%3E");
}
#grid-view:hover,
#table-view:hover,
#grid-view.active,
#table-view.active {
  opacity: 1;
}
.columns-visibility-settings {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.pseudo-link {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}
.overflow-hidden {
  overflow-y: hidden;
}

.__vuescroll {
  height: 100% !important;
}
.__panel {
  height: 100% !important;
  width: 100% !important;
}
.__view,
.table {
  max-width: 100%;
  max-height: 100%;
}

/deep/ th {
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
td,
td div,
td span {
  word-wrap: break-word;
  max-width: 240px;
}

/deep/ .table tbody .custom-checkbox {
  z-index: 0;
}

/deep/ .__bar-is-vertical {
  z-index: 10 !important;
}

.filters-visibility-toggle {
  position: absolute;
  top: 25px;
  right: 5px;
}
.filters-row {
  min-height: 80px;
}
</style>
